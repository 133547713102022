<template>
    <div>
        <Navbar />
        <PageBanner pageTitle="Refer" className="page-banner-area bg-2" />
        <div class="talk-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 order-2">
                        <div class="talk-image" data-tilt>
                            <img src="../../assets/images/talk/talk-2.png" alt="image">

                            <div class="talk-circle">
                                <img src="../../assets/images/talk/talk-circle.png" alt="image">
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="talk-content">
                            <h3>Referral Form</h3>
                            <p>Please enter the referral details</p>
                            <form id="contactFormTwo" @submit.prevent="saveenquiry">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="name" style="padding-left: 5px;padding-bottom: 5px;font-weight: bold;">Name</label>
                                            <input type="text" name="name" id="name" class="form-control" maxlength="40" placeholder="Name" pattern="[a-z A-Z]+">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="age" style="padding-left: 5px;padding-bottom: 5px;font-weight: bold;">Age</label>
                                            <input type="text" name="age" id="age" class="form-control" placeholder="Age" maxlength="3">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="dob" style="padding-left: 5px;padding-bottom: 5px;font-weight: bold;">DOB</label>
                                            <input type="date" name="dob" id="dob" class="form-control" placeholder="Date of Birth">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="mobile" style="padding-left: 5px;padding-bottom: 5px;font-weight: bold;">Mobile</label>
                                            <input type="text" name="mobile" id="mobile" class="form-control" minlength="10" maxlength="10" placeholder="Mobile Number" inputmode="numeric" onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="gender" style="padding-left: 5px;padding-bottom: 5px;font-weight: bold;">Gender</label>
                                            <select name="gender" id="gender" class="form-control">
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="occupation" style="padding-left: 5px;padding-bottom: 5px;font-weight: bold;">Occupation</label>
                                            <input type="text" name="occupation" id="occupation" class="form-control" maxlength="30" placeholder="Occupation / Profession" pattern="[a-z A-Z]+">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="address_line1" style="padding-left: 5px;padding-bottom: 5px;font-weight: bold;">Address Line 1</label>
                                            <input type="text" name="address_line1" id="address_line1" class="form-control" maxlength="200" placeholder="Address Line 1">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="address_line2" style="padding-left: 5px;padding-bottom: 5px;font-weight: bold;">Address Line 2</label>
                                            <input type="text" name="address_line2" id="address_line2" class="form-control" maxlength="200" placeholder="Address Line 2">
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="address" id="address" class="form-control" cols="6" rows="6" maxlength="100" placeholder="Enter Your Address..." pattern="[a-z A-Z]+"></textarea>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Submit<span></span></button>
                                    </div>
                                </div>
                                <div id="error_codes" class="my-3 text-center">

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import { BasePath, instance } from '../../api';
// import { AdminPath, BasePath, instance } from '../../api';
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'
export default {
    name: 'Talk',
    components: {
        //Topbar,
        Navbar,
        PageBanner,
        Footer,
    },
    async mounted() { 	
        this.loginaccess();	       
    },
    methods: {
        // async getCustomerDetails(){ 
        async saveenquiry(){ 
            document.getElementById("error_codes").innerHTML= "";
            // var customer = document.getElementById("customer_id").value;
            var name = document.getElementById("name").value;
            var age = document.getElementById("age").value;
            var dob = document.getElementById("dob").value;
            var mobile = document.getElementById("mobile").value;
            var gender = document.getElementById("gender").value;
            var occupation = document.getElementById("occupation").value;
            var address_line1 = document.getElementById("address_line1").value;
            var address_line2 = document.getElementById("address_line2").value;
            // var address = document.getElementById("address").value;
            if(!name){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter The Name !<span>";
                return false;
            } 
            if(!age){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter The Age !<span>";
                return false;
            } 
            if(!mobile){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter The Valid Mobile Number !<span>";
                return false;
            } 
            if(!gender){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter Gender !<span>";
                return false;
            } 
            if(!occupation){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter The Occupation !<span>";
                return false;
            } 
            if(!dob){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Select Date Of Birth !<span>";
                return false;
            } 
            if(!address_line1){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter Address !<span>";
                return false;
            } 

            let formData    = new FormData();
            var token       = localStorage.getItem('auth_token');
            formData.append('token', token);
            formData.append('name', name);
            formData.append('age', age);
            formData.append('dob', dob);
            formData.append('mobile', mobile);
            formData.append('gender', gender);
            formData.append('occupation', occupation);
            formData.append('address_line1', address_line1);
            formData.append('address_line2', address_line2);
            // formData.append('address', address);
            await instance.post(BasePath+'save-enquiry',formData,{  headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => { console.log(response.data);
                var res = response.data;
                if(res == 'success'){ 
                    document.getElementById("error_codes").innerHTML = "<span class='text-success fw-bold'>Reference Submitted Successfully !<span>";
                    document.getElementById("name").value="";
                    document.getElementById("age").value="";
                    document.getElementById("dob").value="";
                    document.getElementById("mobile").value="";
                    document.getElementById("gender").value="";
                    document.getElementById("occupation").value="";
                    document.getElementById("address_line1").value="";
                    document.getElementById("address_line2").value="";                    
                    setTimeout(function () {
                        document.getElementById("error_codes").innerHTML= "";
                        location.reload(); 
                    },2000); 
                } else{
                    document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Customer already exist!<span>";
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        loginaccess: function(){            
            var token = localStorage.getItem('auth_token'); 
            if(!token){               
				this.$router.push({path: ("/customer-login")});                 
            }
        },
    //     validate() {
    //     var element = document.getElementById('input-field');
    //     element.value = element.value.replace(/[^a-zA-Z0-9@]+/, '');
    // }        
    }     

}

// function validate() {
//   var element = document.getElementById('input-field');
//   element.value = element.value.replace(/[^a-zA-Z0-9@]+/, '');
// }

</script>
